
import Button from "primevue/button";
import ScrollPanel from "primevue/scrollpanel";
import { ComponentPublicInstance } from "vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SyringeIcon from "@/components/icons/SyringeIcon.vue";
import config from "@/config";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

@Component({
  components: {
    SyringeIcon,
    Button,
    ScrollPanel,
  },
})
export default class MenuLinks extends Vue {
  @Ref("menuLinksWrapper") private menuLinksWrapper!: HTMLElement;
  @Ref("scrollPanel") private scrollPanel!: ComponentPublicInstance;

  @Prop() private openVaccinationModule!: () => void;

  private activeGroup: number = 0;
  private scrollHeight: number | null = null;

  readonly accountState: AccountModule = getModule(AccountModule);
  readonly sidebarState: SidebarModule = getModule(SidebarModule);
  readonly viewState: ViewModule = getModule(ViewModule);

  mounted() {
    if (this.menuLinksWrapper) {
      this.scrollHeight = this.menuLinksWrapper.scrollHeight;
    }
  }

  toggleGroup(index: number) {
    if (this.activeGroup === index) {
      this.activeGroup = -1;
    } else {
      this.activeGroup = index;
    }
    this.$nextTick(() => {
      if (this.scrollPanel?.$el?.children[0]?.children[0]) {
        this.scrollPanel.$el.children[0].children[0].scrollTop = +this.scrollHeight! * 3;
      }
    });
  }

  get helpLink(): string {
    return this.$t(`community.url.help${config.role}`).toString();
  }

  get showPatientSearch(): boolean {
    return this.accountState.isProfessionalUser && !this.accountState.isPatientSelected;
  }

  get showDocuments(): boolean {
    return this.accountState.isPatientUser || this.accountState.isPatientSelected;
  }

  get showVaccination(): boolean {
    return (
      config.feature.vaccination &&
      (this.accountState.isPatientUser || this.accountState.isPatientSelected)
    );
  }

  handleClick(): void {
    if (window.innerWidth < 768) {
      this.sidebarState.setIsSidebarOpen(false);
    }
  }
}
