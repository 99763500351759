import { render, staticRenderFns } from "./TabBarMenu.vue?vue&type=template&id=1a58237c&scoped=true"
import script from "./TabBarMenu.vue?vue&type=script&lang=ts"
export * from "./TabBarMenu.vue?vue&type=script&lang=ts"
import style0 from "./TabBarMenu.vue?vue&type=style&index=0&id=1a58237c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a58237c",
  null
  
)

export default component.exports