import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=42d4c638&scoped=true"
import script from "./Navbar.vue?vue&type=script&lang=ts"
export * from "./Navbar.vue?vue&type=script&lang=ts"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=42d4c638&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d4c638",
  null
  
)

export default component.exports