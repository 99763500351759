import { R4 } from "@ahryman40k/ts-fhir-types";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

import config from "@/config";
import { DirectNodeChild, Tree, UnauthorizedError } from "@/global";

import Store from "../index";
import AccountModule, { LocalStorageItemKeys } from "./account";

const accountState = getModule(AccountModule);

const authHeaderFunction = async function (): Promise<string | null> {
  try {
    return accountState.getAuthenticationHeader();
  } catch (error) {
    return null;
  }
};

async function fetchDocuments(): Promise<R4.IBundle> {
  const request = {
    resourceType: "Bundle",
    type: "batch",
    entry: [
      {
        request: {
          method: "GET",
          url: "DocumentReference?status=current,entered-in-error,superseded",
        },
      },
      {
        request: {
          method: "GET",
          url: "List",
        },
      },
    ],
  };

  const url = config.r4.documents.upload;

  const authHeader = await authHeaderFunction();
  if (authHeader == null) {
    throw new Error("No auth Header");
  }

  const response = await window.fetch(encodeURI(url), {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw new UnauthorizedError("toasts.auth.noEPDAccess");
    } else {
      throw new Error("Response not OK");
    }
  }

  const bundle = (await response.json()) as R4.IBundle;
  return bundle;
}

const mapLocalEntries = async function (
  lists: R4.IList[],
  localEntries: Map<string, IDocumentReferenceExtended>
): Promise<Map<string, IDocumentReferenceExtended>> {
  lists.forEach((list) => {
    if (list.entry && list.entry.length > 0) {
      const ref = list.entry![0].item.reference;
      if (ref) {
        const split = ref.split("/");
        const id = split[split.length - 1];

        const homeCommunityID =
          list.extension
            ?.find(
              (ext) =>
                ext.url == "https://api.phellowseven.com/fhir/StructureDefinition/homeCommunityId"
            )
            ?.valueString?.replace("urn:oid:", "") || config.homeCommunity.id;

        const docRef = localEntries.get(homeCommunityID + "-" + id);
        if (docRef) {
          docRef.submissionSet = list;
        }
      }
    }
  });

  return localEntries;
};

@Module({
  dynamic: true,
  store: Store,
  name: "document",
  namespaced: true,
})
export default class DocumentModule extends VuexModule {
  isLoading: boolean = false;
  entries: Map<string, IDocumentReferenceExtended> = new Map();
  entriesVersion: number = 1;
  leafEntries: IDocumentReferenceExtended[] = [];
  relationshipTree: Tree = new Tree(null);
  count: number = +(window.localStorage.getItem(LocalStorageItemKeys.DocumentCount) || 0);

  @Action({ rawError: true })
  async getDocument(id: string): Promise<IDocumentReferenceExtended> {
    const filtered = this.entries.get(id);

    if (filtered) {
      return filtered;
    }

    throw Error("No Document with this ID");
  }

  // @Action({ rawError: true })
  // async getDocumentForLogicalUUID(uuid: string): Promise<IDocumentReferenceExtended> {
  //   const filtered = this.entries.get(id);

  //   if (filtered) {
  //     return filtered;
  //   }

  //   throw Error("No Document with this ID");
  // }

  @Action
  async getDocuments(ids: string[]): Promise<IDocumentReferenceExtended[]> {
    const collected: IDocumentReferenceExtended[] = [];

    ids.forEach((id) => {
      const entry = this.entries.get(id);
      if (entry) {
        collected.push(entry);
      }
    });

    return collected;
  }

  @Mutation
  resetCount() {
    this.count = 0;
    window.localStorage.setItem(LocalStorageItemKeys.DocumentCount, `${this.count}`);
  }

  @Mutation
  addCount(count: number) {
    this.count += count;
    window.localStorage.setItem(LocalStorageItemKeys.DocumentCount, `${this.count}`);
  }

  @Mutation
  clearDocuments() {
    this.entries = new Map();
    this.entriesVersion = 1;
    this.relationshipTree = new Tree(null);
    this.leafEntries = [];
  }

  @Mutation
  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  setRelationshipTree(tree: Tree) {
    this.relationshipTree = tree;
  }

  @Mutation
  setLeafEntries(leafEntries: IDocumentReferenceExtended[]) {
    this.leafEntries = leafEntries;
  }

  @Mutation
  setDocuments(entries: Map<string, IDocumentReferenceExtended>) {
    this.entries = entries;
    this.entriesVersion++;
  }

  @Mutation
  removeEntry(document: IDocumentReferenceExtended) {
    this.entries.delete(`${document.homeCommunityId}-${document.id}`);
    this.entriesVersion++;
  }

  @Action
  removeDocument(document: IDocumentReferenceExtended) {
    this.removeEntry(document);
  }

  get documents(): IDocumentReferenceExtended[] {
    // Makes the getter reactive despite the underlying entries Map not being reactive in Vue 2
    this.entriesVersion;
    return Array.from(this.entries.values());
  }

  @Action({ rawError: true })
  async fetchDocuments() {
    this.setLoading(true);

    let references: R4.IDocumentReference[] = [];
    let lists: R4.IList[] = [];

    try {
      const bundle = await fetchDocuments();

      references = (((bundle.entry!![0].resource as R4.IBundle) || []).entry || []).map(
        (entry) => entry.resource as R4.IDocumentReference
      );
      lists = (((bundle.entry!![1].resource as R4.IBundle) || []).entry || []).map(
        (entry) => entry.resource as R4.IList
      );
    } catch (e) {
      this.setDocuments(new Map());
      this.setRelationshipTree(new Tree(null));
      throw e;
    } finally {
      this.setLoading(false);
    }

    if (references.length != 0) {
      const fetchedEntries = references.map((entry) => entry as IDocumentReferenceExtended);

      let localEntries = new Map<string, IDocumentReferenceExtended>(
        fetchedEntries.map((entry) => {
          const homeCommunityID =
            entry.extension
              ?.find(
                (ext) =>
                  ext.url == "https://api.phellowseven.com/fhir/StructureDefinition/homeCommunityId"
              )
              ?.valueString?.replace("urn:oid:", "") || config.homeCommunity.id;

          entry.homeCommunityId = homeCommunityID!;
          const key = homeCommunityID + "-" + entry.id!;
          return [key, entry];
        })
      );

      localEntries = await mapLocalEntries(lists, localEntries);

      this.setRelationshipTree(new Tree(fetchedEntries));

      localEntries.forEach((ref) => {
        const logicalEntry = ref.extension?.find(
          (ext) => ext.url == "https://api.phellowseven.com/fhir/StructureDefinition/logicalEntry"
        )?.valueReference;

        ref.originalSubmissionSet = lists.find(
          (set) =>
            set.entry?.map((entry) => entry.item?.reference)?.indexOf(logicalEntry?.reference) != -1
        );
      });

      localEntries.forEach((entry) => {
        const id = entry.homeCommunityId + "-" + entry.id!;
        entry.version = this.relationshipTree.getVersion(id) || 1;
        entry.addendumNumber = this.relationshipTree.getAddendumNumber(id) || 1;
      });

      this.setLoading(false);
      this.setDocuments(localEntries);

      const leafEntries = this.relationshipTree
        .getLeaves()
        .map((node) => {
          const nested = this.relationshipTree
            .getParents(node)
            .map((nestedNode) => {
              const entry = this.entries.get(nestedNode.id);
              if (entry) {
                if (nestedNode.directChild) {
                  entry.relationship = {
                    type: nestedNode.type,
                    relatedNode: nestedNode.directChild,
                  };
                }
              }
              return entry;
            })
            .filter((entry) => entry) as IDocumentReferenceExtended[];
          const entry = this.entries.get(node.id) as IDocumentReferenceExtended;
          entry.nested = nested;
          return entry;
        })
        .filter((entry) => entry) as IDocumentReferenceExtended[];
      this.setLeafEntries(leafEntries);
    } else {
      this.setDocuments(new Map());
      this.setRelationshipTree(new Tree(null));
      this.setLoading(false);
    }
  }
}

export interface IDocumentReferenceRelationship {
  type: R4.DocumentReference_RelatesToCodeKind | null;
  relatedNode: DirectNodeChild;
}

export interface IDocumentReferenceExtended extends R4.IDocumentReference {
  submissionSet?: R4.IList;
  originalSubmissionSet?: R4.IList;
  version?: number;
  addendumNumber?: number;
  homeCommunityId: string;
  nested?: IDocumentReferenceExtended[];
  relationship?: IDocumentReferenceRelationship;
}

export interface IDocumentAttachment {
  metadata: IDocumentReferenceExtended;

  content(): Promise<Blob>;
  objectURL(): Promise<string>;
}

export class DocumentAttachment implements IDocumentAttachment {
  metadata: IDocumentReferenceExtended;

  private accountModule: AccountModule;
  private _content: Blob | null = null;

  constructor(metadata: IDocumentReferenceExtended, accountModule: AccountModule) {
    this.accountModule = accountModule;
    this.metadata = metadata;
  }

  async content(): Promise<Blob> {
    if (!this._content) await this.download();

    return this._content!;
  }

  async objectURL(): Promise<string> {
    return URL.createObjectURL(await this.content());
  }

  private async download() {
    const url = this.metadata.content[0].attachment.url!;

    const authHeader = await this.accountModule.getAuthenticationHeader();

    const response = await window.fetch(url, {
      method: "GET",
      headers: {
        Authorization: authHeader,
      },
    });

    this._content = await response.blob();
  }
}

export class ChCdaDocumentAttachment implements IDocumentAttachment {
  attachment: IDocumentAttachment;

  private _objectURL: string | null = null;

  constructor(attachment: IDocumentAttachment) {
    this.attachment = attachment;
  }

  get metadata(): IDocumentReferenceExtended {
    return this.attachment.metadata;
  }

  content(): Promise<Blob> {
    return this.attachment.content();
  }

  async objectURL(): Promise<string> {
    if (this._objectURL) return this._objectURL;

    const content = await this.content();
    if (content.type.indexOf("xml") == -1) {
      this._objectURL = URL.createObjectURL(content);
      return this._objectURL;
    }

    const xml = await content.text();

    const replaced = xml.replace(
      /<\?xml(.+)\?>/,
      `<?xml$1?>\n<?xml-stylesheet type="text/xsl" href="${window.location.origin}/xsl/cda-ch/cda-ch.xsl" ?>`
    );

    const blob = new Blob([new TextEncoder().encode(replaced)], {
      type: "text/xml",
    });

    this._objectURL = URL.createObjectURL(blob);
    return this._objectURL;
  }
}

export class ChFhirDocumentAttachment implements IDocumentAttachment {
  attachment: IDocumentAttachment;

  private accountState: AccountModule;
  private _objectURL: string | null = null;
  private language?: string;

  constructor(attachment: IDocumentAttachment, accountState: AccountModule, language?: string) {
    this.attachment = attachment;
    this.accountState = accountState;
    this.language = language;
  }

  get metadata(): IDocumentReferenceExtended {
    return this.attachment.metadata;
  }

  content(): Promise<Blob> {
    return this.attachment.content();
  }

  async objectURL(): Promise<string> {
    if (this._objectURL) return this._objectURL;

    const content = await this.content();
    if (content.type.indexOf("json") === -1 && content.type.indexOf("xml") === -1) {
      this._objectURL = URL.createObjectURL(content);
      return this._objectURL;
    }

    // Transform to FHIR XML
    let xml;
    if (content.type.indexOf("json") !== -1) {
      const response = await window.fetch(config.r4.documents.upload + "$convert", {
        method: "POST",
        headers: {
          Authorization: await this.accountState.getAuthenticationHeader(),
          Accept: "application/fhir+xml",
          "Content-Type": "application/fhir+json",
        },
        body: await this.content(),
      });

      xml = await response.text();
    } else {
      xml = await this.content();
    }

    // XLST Transform Service
    let conversionUrl = config.eprServices.conversion;
    if (this.language) {
      conversionUrl += `?locale=${this.language}`;
    }
    const html = await window.fetch(conversionUrl, {
      method: "POST",
      headers: {
        Authorization: await this.accountState.getAuthenticationHeader(),
        "Content-Type": `application/fhir+xml; format=${this.metadata.content[0].format!.code}`,
        Accept: `text/html`,
      },
      body: xml,
    });

    this._objectURL = URL.createObjectURL(await html.blob());
    return this._objectURL;
  }
}
