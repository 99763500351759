
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AccountModule from "@/store/modules/account";
import { breakpoints } from "@/theme";

@Component({})
export default class Tile extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly isLoading!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly fitContent!: boolean;

  accountState = getModule(AccountModule);

  tileTop: number = 0;
  spinnerTop: number = 0;
  marginBottom: string | number = "lg";

  mounted() {
    const element = this.$refs.tile as HTMLElement;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      this.updatePositionsAndSize();
    });

    resizeObserver.observe(element);

    this.$once("hook:beforeDestroy", () => {
      resizeObserver.disconnect();
    });
  }

  get principals() {
    return this.accountState.proxies;
  }

  // Principals are loaded asynchronously, so we need to watch for changes which
  // can impact the tile's top position.
  @Watch("principals", { deep: true })
  onPrincipalsChange() {
    window.setTimeout(() => this.updatePositionsAndSize(), 10);
  }

  updatePositionsAndSize() {
    const element = this.$refs.tile as HTMLElement;
    if (!element) {
      return;
    }

    const marginBottom = window.innerWidth < breakpoints.md ? 0 : "2rem"; // 2rem = lg
    this.marginBottom = window.innerWidth < breakpoints.md ? 0 : "lg";

    const tileHeight = element.getBoundingClientRect().height;
    const tileTop = element.getBoundingClientRect().top;
    this.tileTop = tileTop;

    if (!this.fitContent) {
      element.style.minHeight = `calc(${window.innerHeight}px - ${tileTop}px - ${marginBottom})`;
    }
    this.spinnerTop = Math.min(tileHeight, window.innerHeight - tileTop) / 2;
  }
}
