var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{ref:"menuLinksWrapper",staticClass:"relative",class:{ 'sidebar-open': _vm.sidebarState.isSidebarOpen }},[_c('ScrollPanel',{ref:"scrollPanel",staticClass:"absolute w-full h-full"},[_c('div',{staticClass:"flex flex-col space-y-2",on:{"click":_vm.handleClick}},[(!_vm.viewState.isSmallScreen)?[(_vm.showPatientSearch)?_c('router-link',{attrs:{"to":{ name: 'patients' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              value: _vm.$t('patients.search.title').toString(),
              disabled: _vm.sidebarState.isSidebarOpen,
            }),expression:"{\n              value: $t('patients.search.title').toString(),\n              disabled: sidebarState.isSidebarOpen,\n            }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('patients.search.title') : '',"icon":"pi pi-search","data-cy":"patient-search-nav-item"}})],1):_vm._e(),(_vm.showDocuments)?_c('router-link',{attrs:{"to":{ name: 'documents' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              value: _vm.$t('navigation.actions.documents').toString(),
              disabled: _vm.sidebarState.isSidebarOpen,
            }),expression:"{\n              value: $t('navigation.actions.documents').toString(),\n              disabled: sidebarState.isSidebarOpen,\n            }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('navigation.actions.documents') : '',"icon":"pi pi-file","data-cy":"documents-nav-item"}})],1):_vm._e(),(_vm.showVaccination)?_c('a',{attrs:{"tabindex":"-1"},on:{"click":_vm.openVaccinationModule}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              value: _vm.$t('navigation.actions.vaccination').toString(),
              disabled: _vm.sidebarState.isSidebarOpen,
            }),expression:"{\n              value: $t('navigation.actions.vaccination').toString(),\n              disabled: sidebarState.isSidebarOpen,\n            }",modifiers:{"right":true}}],staticClass:"flex flex-row p-button-text p-button-secondary group",attrs:{"data-cy":"vaccination-nav-item"}},[_c('div',{staticClass:"text-[1.3rem] h-6 p-button-icon p-button-icon-left transition-transform group-hover:translate-x-[.15rem] group-hover:translate-y-[.15rem]"},[_c('SyringeIcon',{staticClass:"-ml-1 mt-[2px]"})],1),(_vm.sidebarState.isSidebarOpen)?_c('span',{staticClass:"p-button-label"},[_vm._v(" "+_vm._s(_vm.$t("navigation.actions.vaccination"))+" ")]):_vm._e()])],1):_vm._e(),(_vm.accountState.isPatientUser)?_c('router-link',{attrs:{"to":{ name: 'documentAccess' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              value: _vm.$t('navigation.actions.documentAccess').toString(),
              disabled: _vm.sidebarState.isSidebarOpen,
            }),expression:"{\n              value: $t('navigation.actions.documentAccess').toString(),\n              disabled: sidebarState.isSidebarOpen,\n            }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('navigation.actions.documentAccess') : '',"icon":"pi pi-key","data-cy":"access-rights-nav-item"}})],1):_vm._e(),(_vm.accountState.isPatientUser)?_c('router-link',{attrs:{"to":{ name: 'accessLogs' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              value: _vm.$t('navigation.actions.accessLogs').toString(),
              disabled: _vm.sidebarState.isSidebarOpen,
            }),expression:"{\n              value: $t('navigation.actions.accessLogs').toString(),\n              disabled: sidebarState.isSidebarOpen,\n            }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('navigation.actions.accessLogs') : '',"icon":"pi pi-list","data-cy":"access-logs-nav-item"}})],1):_vm._e(),(_vm.accountState.isPatientUser)?_c('router-link',{attrs:{"to":{ name: 'userSettings' },"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
              value: _vm.$t('navigation.actions.settings').toString(),
              disabled: _vm.sidebarState.isSidebarOpen,
            }),expression:"{\n              value: $t('navigation.actions.settings').toString(),\n              disabled: sidebarState.isSidebarOpen,\n            }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('navigation.actions.settings') : '',"icon":"pi pi-cog","data-cy":"settings-nav-item"}})],1):_vm._e()]:_vm._e(),_c('a',{attrs:{"target":"_blank","href":_vm.helpLink,"tabindex":"-1"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
            value: _vm.$t('navigation.actions.help').toString(),
            disabled: _vm.sidebarState.isSidebarOpen,
          }),expression:"{\n            value: $t('navigation.actions.help').toString(),\n            disabled: sidebarState.isSidebarOpen,\n          }",modifiers:{"right":true}}],staticClass:"p-button-text p-button-secondary external-link-button",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('navigation.actions.help').toString() : '',"icon":"pi pi-question-circle","data-cy":"help-nav-item"}})],1)],2),(_vm.sidebarState.isSidebarOpen)?_c('div',{staticClass:"flex flex-col space-y-2 pt-2"},[_c('a',{attrs:{"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.toggleGroup(2)}}},[_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.sidebarState.isSidebarOpen ? _vm.$t('navigation.actions.legalNotes').toString() : '',"icon":`pi ${_vm.activeGroup === 2 ? 'pi-chevron-down' : 'pi-chevron-right'}`}})],1),(_vm.activeGroup === 2)?[_c('a',{attrs:{"target":"_blank","href":_vm.$t('community.url.dataProtection').toString(),"tabindex":"-1"}},[_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.$t('navigation.actions.dataProtection'),"icon":"pi pi-external-link"}})],1),_c('a',{attrs:{"target":"_blank","href":_vm.$t('community.url.termsOfUse').toString(),"tabindex":"-1"}},[_c('Button',{staticClass:"p-button-text p-button-secondary",attrs:{"label":_vm.$t('navigation.actions.termsOfUse'),"icon":"pi pi-external-link"}})],1)]:_vm._e()],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }